<script>
import EditCleaningSettingsModal from "@/redem/datafiles/components/modals/edit-cleaning-settings-modal.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import ScriptWidget from "./nipo-script-widget.vue";

export default {
  props: {
    datapoints: Object,
    returnToResult: Boolean,
    datafileId: String,
  },
  components: {
    ScriptWidget,
    EditCleaningSettingsModal
  },
  data() {
    return {
      enableRedemApiScript: "redem_api",
      invisibleFontForOdin:
        '*FONT 999 "1 ARIAL (255 255 255)"',
      endScript: `*QUESTION 40000 *ALPHA 320L250 *NON
Your results are being submitted ...
 *FONT 999 [payload=###LAST_QUESTION###]*FONT 0 
 ** =======================================================================================
 *QUESTION 4000000 *DUMMY 
 ** =======================================================================================
 *END`,
      nipoScriptUrl: process.env.VUE_APP_NIPO_SCRIPT_URL,
      /****** Operational Varaibles *****/
      isExpanded: true,
      copyEnableRedemApiScriptSuccess: false,
      copyEnableRedemApiScriptFail: false,
      copyInvisibleFontForOdinSuccess: false,
      copyInvisibleFontForOdinFail: false,
      copyEndScriptSuccess: false,
      copyEndScriptFail: false,
      displayEditCleaningSettingsModal: false
    };
  },
  created() {
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });
  },
  methods: {
    copyEnableRedemApiScript() {
      try {
        navigator.clipboard.writeText(this.enableRedemApiScript);
        this.copyEnableRedemApiScriptSuccess = true;
      } catch (err) {
        this.copyEnableRedemApiScriptFail = true;

        let self = this;
        setTimeout(function () {
          self.copyEnableRedemApiScriptFail = false;
        }, 2000);
        throw err;
      }
    },
    copyInvisibleFontForOdin() {
      try {
        navigator.clipboard.writeText(this.invisibleFontForOdin);
        this.copyInvisibleFontForOdinSuccess = true;
      } catch (err) {
        this.copyInvisibleFontForOdinFail = true;

        let self = this;
        setTimeout(function () {
          self.copyInvisibleFontForOdinFail = false;
        }, 2000);
        throw err;
      }
    },
    copyEndScriptToClipboard() {
      try {
        navigator.clipboard.writeText(this.endScript);
        this.copyEndScriptSuccess = true;
      } catch (err) {
        this.copyEndScriptFail = true;

        let self = this;
        setTimeout(function () {
          self.copyEndScriptFail = false;
        }, 2000);
        throw err;
      }
    },
    clickFinishButton() {
      // redirect to datafiles page
      this.$router.push("/datafiles");
    },
    clickEditCleaningSettings() {
      this.displayEditCleaningSettingsModal = true;
    },
    clickBackToResult() {
      this.$router.push({
        path: "datafile-result",
        query: { datafileId: this.datafileId }
      })
    }   
  }
};
</script>

<template>
  <div>
    <!-- Instruction Card -->
    <div class="row">
      <div class="col-md-8">
        <div class="card-title">
          {{ $t("integrationInstructions.nipo.intro.header") }}
        </div>
        <div class="card-text">
          <ul>
            <li>
              {{ $t("integrationInstructions.nipo.intro.description1") }}
            </li>
            <li>
              {{ $t("integrationInstructions.nipo.intro.description2") }}
            </li>
            <li>
              {{ $t("integrationInstructions.nipo.intro.description3") }}
              <ul>
                <li>
                  {{ $t("integrationInstructions.nipo.intro.questionType1") }}
                </li>
                <li>
                  {{ $t("integrationInstructions.nipo.intro.questionType2") }}
                </li>
                <li>
                  {{ $t("integrationInstructions.nipo.intro.questionType3") }}
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <b-button
          variant="primary"
          @click="clickEditCleaningSettings()"
          class="btn-sm"
        >
          <i class="bx bx-brush mr-2"></i>
          {{ $t("integrationInstructions.general.EditCleaningSettingButton") }}
        </b-button>
      </div>
    </div>
    <!-- Step 1 - Download Theme file & Upload to Nfield-->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              {{ $t("integrationInstructions.nipo.step1.header") }}
            </div>
            <div class="card-text mt-3">
              <ul>
                <li>
                  <label>
                    {{ $t("integrationInstructions.nipo.step1.point1.title") }}
                  </label>
                  <div class="mt-1">
                    <a class="btn btn-light" :href="this.nipoScriptUrl">
                      <i class="bx bx-download font-size-18 mr-2"></i>
                      {{
                        $t(
                          "integrationInstructions.nipo.step1.point1.downloadButton"
                        )
                      }}
                    </a>
                  </div>
                </li>
                <li class="mt-3">
                  <label>
                    {{ $t("integrationInstructions.nipo.step1.point2.title") }}
                  </label>
                  <div>
                    {{
                      $t(
                        "integrationInstructions.nipo.step1.point2.description"
                      )
                    }}
                  </div>
                </li>
                <li class="mt-3">
                  <label>
                    {{ $t("integrationInstructions.nipo.step1.point3.title") }}
                  </label>
                  <div>
                    {{
                      $t(
                        "integrationInstructions.nipo.step1.point3.description1"
                      )
                    }}
                    <br />
                    {{
                      $t(
                        "integrationInstructions.nipo.step1.point3.description2"
                      )
                    }}
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 2 - Enable Redem API -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              {{ $t("integrationInstructions.nipo.step2.header") }}
            </div>
            <div class="card-text mt-3">
              <ul>
                <li>
                  <label>
                    {{ $t("integrationInstructions.nipo.step2.point1") }}
                  </label>
                  <div class="row mt-2">
                    <div class="col-md-3 text-center">
                      <pre
                        class="font-size-14 pt-2 pb-2"
                        style="background: #f5f5f5"
                      >
*TEMPLATE "NfieldChicago;<span style="color:#00a181">redem_api</span>" </pre>
                    </div>
                  </div>

                  <!-- Enable Redem API -->
                  <div class="input-group bg-light rounded mt-2">
                    <input
                      type="text"
                      class="form-control bg-transparent border-0"
                      v-model="this.enableRedemApiScript"
                      disabled
                    />

                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-addon2"
                      @click="copyEnableRedemApiScript()"
                    >
                      <span v-if="this.copyEnableRedemApiScriptSuccess">
                        <b-badge
                          class="mr-2"
                          style="font-size: 12px"
                          variant="success"
                        >
                        {{ $t("integrationInstructions.general.copied") }}
                        </b-badge></span
                      >
                      <i class="bx bx-copy-alt"></i>
                    </button>
                  </div>
                </li>
                <li class="mt-4">
                  <label>{{
                    $t("integrationInstructions.nipo.step2.point2.title")
                  }}</label>
                  <div>
                    {{
                      $t(
                        "integrationInstructions.nipo.step2.point2.description"
                      )
                    }}
                  </div>
                  <!-- Invisible Font for Odin -->
                  <div class="input-group bg-light rounded mt-3">
                    <input
                      type="text"
                      class="form-control bg-transparent border-0"
                      v-model="this.invisibleFontForOdin"
                      disabled
                    />

                    <button
                      class="btn btn-light"
                      type="button"
                      id="button-addon2"
                      @click="copyInvisibleFontForOdin()"
                    >
                      <span v-if="this.copyInvisibleFontForOdinSuccess">
                        <b-badge
                          class="mr-2"
                          style="font-size: 12px"
                          variant="success"
                        >
                        {{ $t("integrationInstructions.general.copied") }}
                        </b-badge></span
                      >
                      <i class="bx bx-copy-alt"></i>
                    </button>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Step 3 - Payloads -->
    <div class="row justify-content-center">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="card-title">
              {{ $t("integrationInstructions.nipo.step3.header") }}
            </div>
            <div class="card-text mt-3">
              <ul>
                <li>
                  <label
                    >{{ $t("integrationInstructions.nipo.step3.point1") }}
                  </label>
                </li>
                <li>
                  <label>
                    {{ $t("integrationInstructions.nipo.step3.point2") }}
                  </label>
                  <!-- Question Scripts -->
                  <div
                    v-for="(datapoint, datapointIndex) in datapoints"
                    :key="datapointIndex"
                    class="mt-3"
                  >
                    <label> {{ datapoint.questionIdentifier }}</label>
                    <ScriptWidget :datapointsParameters="datapoint" />
                  </div>

                  <!-- End Script -->
                  <div class="mt-3">
                    <label> End Script </label>
                    <p>
                      {{ $t("integrationInstructions.nipo.step3.endScript") }}
                    </p>
                    <div class="input-group bg-light rounded">
                      <textarea
                        type="text"
                        class="form-control bg-transparent border-0"
                        v-model="this.endScript"
                        disabled
                        style="height: 155px"
                      />

                      <button
                        class="btn btn-light"
                        type="button"
                        id="button-addon2"
                        @click="copyEndScriptToClipboard()"
                      >
                        <span v-if="this.copyEndScriptSuccess">
                          <b-badge
                            class="mr-2"
                            style="font-size: 12px"
                            variant="success"
                          >
                          {{ $t("integrationInstructions.general.copied") }}
                          </b-badge></span
                        >
                        <i class="bx bx-copy-alt"></i>
                      </button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Button Container -->
    <div
      class="fixed-bottom static-buttons"
      v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
    >
      <div class="card-body col-md-12 row">
        <div class="col-md-12 text-right">
          <a href="https://help.redem.io/" target="_blank"
            >{{ $t("integrationInstructions.general.bestPracticesButton") }}
            <i class="bx bx-link-external ml-1"></i
          ></a>

          <b-button
            v-if="!returnToResult"
            variant="primary"
            @click="clickFinishButton()"
            class="ml-4 mr-2"
          >
            {{ $t("integrationInstructions.general.finishButton") }}
            <i class="bx bx-check-double"></i>
          </b-button>

          <b-button
            v-if="returnToResult"
            variant="primary"
            @click="clickBackToResult()"
            class="ml-4 mr-2"
          >
          {{ $t("integrationInstructions.general.backButton") }}
            <i class='bx bx-right-arrow-alt'></i>
          </b-button>
        </div>
      </div>
    </div>
    <!-- Edit Cleaning Settings Modal -->
    <EditCleaningSettingsModal
      v-if="displayEditCleaningSettingsModal"
      :visible="displayEditCleaningSettingsModal"
      @close="displayEditCleaningSettingsModal = false"
    />    
  </div>
</template>

<style scoped>
.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}

.pointer {
  cursor: pointer;
}
</style>
