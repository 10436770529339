<script>
import EditCleaningSettingsModal from "@/redem/datafiles/components/modals/edit-cleaning-settings-modal.vue";
import { EventBus } from "@/shared/util/event-bus.js";
import ScriptWidget from "./ingress-script-widget.vue";

export default {
  props: {
    datapoints: Object,
    datafileSecretKey: Number,
    returnToResult: Boolean,
    datafileId: String
  },
  components: {
    ScriptWidget,
    EditCleaningSettingsModal
  },
  data() {
    return {
      endScript:
        "// ReDem Quality Check" +
        "\n[input; name:ReDem Quality Check Passed-; back: hidden; redem_quality_check: %TAN%,null,END,null," +
        this.datafileSecretKey +
        ";] \n ⌛️ Loading...\n-[input]",
      /****** Operational Varaibles *****/
      isExpanded: true,
      copyEndScriptSuccess: false,
      copyEndScriptFail: false,
      displayEditCleaningSettingsModal: false
    };
  },
  created() {
    //check whatever settings saved on navbar on page load
    const navBarSetting = window.localStorage.getItem("navBarSetting");
    if (navBarSetting) this.isExpanded = navBarSetting == "expandNav";

    EventBus.$on("sidebar-toggle-action", async () => {
      this.isExpanded = !this.isExpanded;
    });
  },
  methods: {
    copyEndScriptToClipboard() {
      try {
        navigator.clipboard.writeText(this.endScript);
        this.copyEndScriptSuccess = true;
      } catch (err) {
        this.copyFail = true;

        let self = this;
        setTimeout(function () {
          self.copyEndScriptFail = false;
        }, 2000);
        throw err;
      }
    },
    clickFinishButton() {
      // redirect to datafiles page
      this.$router.push("/datafiles");
    },
    clickEditCleaningSettings() {
      this.displayEditCleaningSettingsModal = true;
    },
    clickBackToResult() {
      this.$router.push({
        path: "datafile-result",
        query: { datafileId: this.datafileId }
      })
    }
  }
};
</script>

<template>
  <div>
    <!-- Instruction Card -->
    <div class="row">
      <div class="col-md-8">
        <div class="card-title">
          {{ $t("integrationInstructions.ingress.step1.header") }}
        </div>
        <div class="card-text">
          <ul>
            <li>
              {{ $t("integrationInstructions.ingress.step1.text1") }}
            </li>
            <li>
              {{ $t("integrationInstructions.ingress.step1.text2") }}
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-4 text-right">
        <b-button
          variant="primary"
          @click="clickEditCleaningSettings()"
          class="btn-sm"
        >
          <i class="bx bx-brush mr-2"></i>
          {{ $t("integrationInstructions.general.EditCleaningSettingButton") }}
        </b-button>
      </div>
    </div>

    <!-- Scripts Cards -->
    <div class="row">
      <div class="col-md-12">
        <!-- Pages Scripts -->
        <div
          v-for="(datapoint, datapointIndex) in datapoints"
          :key="datapointIndex"
          class="mt-3"
        >
          <div class="card">
            <div class="card-body">
              <label> {{ datapoint.pageTitle }}</label>
              <p v-if="datapoint.description">
                {{ datapoint.description }}
              </p>
              <p v-if="datapoint.pageTitle === 'Start Page'">
                {{ $t("integrationInstructions.ingress.start.description") }}
              </p>
              <ScriptWidget :datapointsParameters="datapoint" />
            </div>
          </div>
        </div>

        <!-- End Script -->
        <div class="card">
          <div class="card-body">
            <label> ReDem® End Page</label>
            <p>
              {{ $t("integrationInstructions.ingress.end.description") }}
            </p>
            <div class="input-group bg-light rounded">
              <textarea
                type="text"
                class="form-control bg-transparent border-0"
                v-model="this.endScript"
                disabled
                style="height: 120px"
              />

              <button
                class="btn btn-light"
                type="button"
                id="button-addon2"
                @click="copyEndScriptToClipboard()"
              >
                <span v-if="this.copyEndScriptSuccess">
                  <b-badge
                    class="mr-2"
                    style="font-size: 12px"
                    variant="success"
                  >
                    {{ $t("integrationInstructions.general.copied") }}
                  </b-badge></span
                >
                <i class="bx bx-copy-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Button Container -->
    <div
      class="fixed-bottom static-buttons"
      v-bind:class="[isExpanded ? 'expanded' : 'collapsed']"
    >
      <div class="card-body col-md-12 row">
        <div class="col-md-12 text-right">
          <a
            href="https://help.redem.io/docs/data-import/live-import-integrations/keyingress-integration/"
            target="_blank"
            >{{ $t("integrationInstructions.general.bestPracticesButton") }}
            <i class="bx bx-link-external ml-1"></i
          ></a>

          <b-button
            v-if="!returnToResult"
            variant="primary"
            @click="clickFinishButton()"
            class="ml-4 mr-2"
          >
            {{ $t("integrationInstructions.general.finishButton") }}
            <i class="bx bx-check-double"></i>
          </b-button>

          <b-button
            v-if="returnToResult"
            variant="primary"
            @click="clickBackToResult()"
            class="ml-4 mr-2"
          >
          {{ $t("integrationInstructions.general.backButton") }}
            <i class='bx bx-right-arrow-alt'></i>
          </b-button>
        </div>
      </div>
    </div>

    <!-- Edit Cleaning Settings Modal -->
    <EditCleaningSettingsModal
      v-if="displayEditCleaningSettingsModal"
      :visible="displayEditCleaningSettingsModal"
      @close="displayEditCleaningSettingsModal = false"
    />
  </div>
</template>

<style scoped>
.static-buttons {
  z-index: 1;
  margin: 0;
  background-color: white;
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.06), 0 1px 0 0 rgba(0, 0, 0, 0.02);
}

.collapsed {
  padding-left: 75px;
}

.expanded {
  padding-left: 250px;
}

.pointer {
  cursor: pointer;
}
</style>
