<script>
export default {
  props: {
    datapointsParameters: Object
  },
  data() {
    return {
      parameter:
        this.datapointsParameters.questionQualityScores +
        "," +
        this.datapointsParameters.questionPosition +
        "," +
        this.datapointsParameters.questionKey +
        "," +
        this.datapointsParameters.questionSecretKey,
      finalScript: null,
      /****** Operational Varaibles *****/
      copySuccess: false,
      copyFail: false
    };
  },
  mounted() {
    this.finalScript = "*FONT 999 [payload=" + this.parameter + "]*FONT 0";
  },
  methods: {
    copyScriptToClipboard() {
      try {
        navigator.clipboard.writeText(this.finalScript);
        this.copySuccess = true;
      } catch (err) {
        this.copyFail = true;

        let self = this;
        setTimeout(function () {
          self.copyFail = false;
        }, 2000);
        throw err;
      }
    }
  }
};
</script>

<template>
  <div class="input-group bg-light rounded">
    <input
      type="text"
      class="form-control bg-transparent border-0"
      v-model="this.finalScript"
      disabled
    />

    <button
      class="btn btn-light"
      type="button"
      id="button-addon2"
      @click="copyScriptToClipboard()"
    >
      <span v-if="this.copySuccess">
        <b-badge class="mr-2" style="font-size: 12px" variant="success">
          {{
            $t("integrationInstructions.general.copied")
          }}
          </b-badge
        ></span
      >
      <i class="bx bx-copy-alt"></i>
    </button>
  </div>
</template>
