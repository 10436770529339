<script>
import DatafileService from "@/api/services/datafile.service.js";
import appConfig from "@/app.config";
import Layout from "@/router/layouts/main";
import PageHeader from "@/shared/components/page-header";
import DataFetchingErrorWidget from "@/shared/components/widgets/data-fetching-error.vue";
import ForstaInstructions from "../components/widgets/integration-instructions/forsta-instructions.vue";
import IngressInstructions from "../components/widgets/integration-instructions/ingress-instructions.vue";
import NIPOInstructions from "../components/widgets/integration-instructions/nipo-instructions.vue";

export default {
  page: {
    title: "Integration Instructions",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: {
    Layout,
    PageHeader,
    DataFetchingErrorWidget,
    IngressInstructions,
    NIPOInstructions,
    ForstaInstructions
  },
  data() {
    return {
      datafileId: this.$route.query.datafileId,
      returnToResult: this.$route.query.returnToResult ? this.$route.query.returnToResult: false,
      datafile: null,
      trackTotalSurveyDuration: false,
      enableCopyPasteCheck: false,
      datapointsParameters: [],
      pages: [],
      /*** Operational Variables ***/
      isLoading: true
    };
  },
  computed: {
    computedItems() {
      var items = [
        {
          text: "Dashboard",
          href: "/"
        },
        {
          text: this.$i18n.t("integrationInstructions.header"),
          active: true
        }
      ];
      return items;
    }
  },
  async mounted() {
    await this.getDraftDatafile(this.datafileId);
    this.isLoading = false;
  },
  methods: {
    getDPCode(datapointType) {
      // convert datapoint type to code like IBS, OES etc. and return the code
      switch (datapointType) {
        case "OPEN_ENDED":
          return "OES";
        case "TIMESTAMP":
          return "TS";
        case "ITEMBATTERY":
          return "IBS";
      }
    },
    async getDraftDatafile(datafileId) {
      this.datafile = await DatafileService.getDraftDatafile(datafileId);

      if (this.datafile) {
        let arrayToGroup = this.datafile.datapointsMetadata;
        let isStartingQuestionDefined = false;

        let groupedDataPointsForPages = arrayToGroup.reduce((x, y) => {
          (x[y.pageNumber] = x[y.pageNumber] || []).push(y);
          return x;
        }, {});

        for (const key in groupedDataPointsForPages) {
          let questionIdentifier = "";
          let questionSecretKey = this.datafile.datafileSecretKey;
          let questionQualityScores = null;
          let questionPosition = null;
          let pageTitle = null;
          let questionTitle = null;
          let isEnableCopyPasteCheck = false;

          for (let i = 0; i < groupedDataPointsForPages[key].length; i++) {
            // set question possition
            if (groupedDataPointsForPages[key][i].startingQuestion) {
              questionPosition = "START";
              isStartingQuestionDefined = true;
            }

            //remove _Duration for TS datapoints by splitting
            questionIdentifier =
              groupedDataPointsForPages[key][i].dataPointIdentifier.split(
                "_Duration"
              )[0]; //get the 1st part of the identifier without _Duration for the ingress script

            questionQualityScores = questionQualityScores
              ? questionQualityScores +
                "+" +
                this.getDPCode(groupedDataPointsForPages[key][i].dataPointType)
              : this.getDPCode(groupedDataPointsForPages[key][i].dataPointType);

            if (groupedDataPointsForPages[key][i].questionTitle) {
              // OES
              questionTitle = groupedDataPointsForPages[key][i].questionTitle;
              isEnableCopyPasteCheck = groupedDataPointsForPages[key][i].isEnableCopyPasteCheck;
            }
            // set question quality Score
            pageTitle = groupedDataPointsForPages[key][i].pageTitle;
          }

          let questionParameterObject = {
            questionQualityScores: questionQualityScores,
            questionPosition: questionPosition,
            questionKey: questionIdentifier,
            questionSecretKey: questionSecretKey,
            questionIdentifier: questionIdentifier,
            questionTitle,
            isEnableCopyPasteCheck,
            pageTitle: pageTitle
          };

          if (questionParameterObject.questionIdentifier === "Survey") {
            this.trackTotalSurveyDuration = true;
            questionParameterObject.pageTitle = "Start Page";
            questionParameterObject.questionQualityScores = null;
            questionParameterObject.questionPosition = "START";
            questionParameterObject.questionKey = null;
          }

          this.datapointsParameters.push(questionParameterObject);
        }

        // remove Total Time duration data point if stating question is defined
        if (isStartingQuestionDefined) {
          this.datapointsParameters.shift();
        }
      }
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader
      :title="this.$t('integrationInstructions.header')"
      :items="computedItems"
    />

    <!-- Page Loading -->
    <div v-if="isLoading" class="row justify-content-center">
      <div class="col-6 text-center p-5">
        <b-spinner
          style="width: 5rem; height: 5rem"
          class="m-2"
          variant="mid"
          role="status"
        ></b-spinner>
      </div>
    </div>

    <!-- Display Instruction Fetching Error -->
    <div v-if="!isLoading && !datafile" class="row justify-content-center">
      <div class="col-6 text-center p-5">
        <DataFetchingErrorWidget />
      </div>
    </div>

    <!-- Display Instruction -->
    <div v-if="!isLoading && datafile">
      <div class="row">
        <div class="col-md-12">
          <IngressInstructions
            v-if="datafile.integration.name === 'keyingress'"
            :datafileSecretKey="datafile.datafileSecretKey"
            :datapoints="datapointsParameters"
            :returnToResult="returnToResult"
            :datafileId="datafileId"
          />
          <NIPOInstructions
            v-if="datafile.integration.name === 'NIPO'"
            :datafileSecretKey="datafile.datafileSecretKey"
            :datapoints="datapointsParameters"
            :returnToResult="returnToResult"
            :datafileId="datafileId"
          />
          <ForstaInstructions
            v-if="datafile.integration.name === 'Forsta Plus'"
            :datafileSecretKey="datafile.datafileSecretKey"
            :datafileId="datafileId"
            :datapoints="datapointsParameters"
            :trackTotalSurveyDuration="trackTotalSurveyDuration"
            :returnToResult="returnToResult"
          />
        </div>
      </div>
    </div>
  </Layout>
</template>
